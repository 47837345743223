import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import './Thanks.scss';

const Thanks = () => {

  return (
    <section className='thanks'>

      <Helmet>
          <meta charSet="utf-8" />
          <title>Дякую Вам</title>
          <script>
          {`
            // Ваш код Facebook Pixel
            !function(f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1055364738832027');
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>
      <h2 className='thanks__title'>Дякуємо за вибір!</h2>
      <p className='thanks__text'>Наш менеджер зв'яжеться з вами та проконсультує з будь-яких питань найближчим часом :)</p>
      <Link to={'/'} className='thanks__button button'>НА ГОЛОВНУ</Link>
    </section>
  )
  
}

export default Thanks;