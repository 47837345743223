import { useEffect, useState } from 'react'

import { Sling as Hamburger } from 'hamburger-react'

import './Header.scss'

import art from '../../image/services/art/art.png'
import colague from '../../image/services/colague/colague.webp'
import blackAndWhite from '../../image/services//black-white/black-white.webp'
import interier from '../../image/services/interier/interier.webp'
import loveIs from '../../image/services/love-is/love-is.webp'
import changeBg from '../../image/services/picture-with-exchange-phon/change_bg.webp'
import simple from '../../image/services/simpe-picture-from-photo/simple.webp'
import standart from '../../image/services/standart-mix/standart.webp'
import simsons from '../../image/services/simsons/simsons.webp'
import time from '../../image/services/picture-with-clock/time.webp'

export const servicesData = [
  {
    img: art,
    button: 'Арт-поєднання'
  },
  {
    img: colague,
    button: 'Колаж'
  },
  {
    img: simple,
    button: 'Просто картина',
    classes: 'services__img-rounded'
  },
  {
    img: standart,
    button: 'Стандартне поєднання'
  },
  {
    img: loveIs,
    button: 'Love is'
  },

  {
    img: simsons,
    button: 'Сімпсони'
  },

  {
    img: time,
    button: 'Картина з годинником'
  },
  {
    img: interier,
    button: "Інтер'єрна картина",
    classes: 'services__img-rounded'
  },
  {
    img: blackAndWhite,
    button: 'З чорно-білої – кольорова'
  },

  {
    img: changeBg,
    button: 'Картина з заміною фону',
    classes2: 'services__img-center'
  }
]

const Header = ({ modal, setTypePicture }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    showMenu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '')
  }, [showMenu])



  const checkedMenu = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function getRandomDigit() {
    return Math.floor(Math.random() * 10); 
  }

  return (
    <>
      <header className={`header desktop ${isFixed ? 'fixed' : ''}`}>
        <div className='header__container'>
          <nav className='header__nav nav'>
            <ul className='nav__list'>
              <li className='nav__item'>
                <a href='#services' className='nav__link'>
                  Послуги
                </a>
              </li>
              {/* <li className='nav__item'>
                <a href='#price' className='nav__link'>
                  Ціни
                </a>
              </li> */}
              <li className='nav__item'>
                <a href='#size' className='nav__link'>
                  Розміри
                </a>
              </li>
              <li className='nav__item'>
                <a href='#faq' className='nav__link'>
                  Питання
                </a>
              </li>
            </ul>
          </nav>

          <ul className='header__order order__list'>
            <li className='order__item'>
              <a href='tel: +380976068288' className='order__link'>
              +38 (097) 606 8288
              </a>
            </li>
            <li className='order__item'>
              <a
                href='https://www.instagram.com/omnia_holst/?igsh=dXN4djl5NjE2NmMx'
                target='_blank'
                rel='noopener noreferrer'
                className='order__item-icon'
              ></a>
            </li>
            <li className='order__item'>
              <button
                className='order__button button f-size-16'
                onClick={() => {
                  setTypePicture(servicesData[getRandomDigit()].button)
                  modal(true)
                }}
              >
                Замовити
              </button>
            </li>
          </ul>
        </div>
      </header>

      <header className={`header header__mobile ${isFixed ? 'fixed' : ''}`}>
        <ul className='header__order header__order-mobile order__list'>
          <li className='order__item'>
            <a
              href='https://www.instagram.com/omnia_holst/?igsh=dXN4djl5NjE2NmMx'
              target='_blank'
              rel='noopener noreferrer'
              className='order__item-icon'
            ></a>
          </li>
          <li className='order__item'>
            <a href='tel: +380976068288' className='order__link'>
              +38 (097) 606 8288
            </a>
          </li>
          <li className='order__item'>
            <Hamburger toggled={showMenu} toggle={setShowMenu} />
          </li>
        </ul>

        {showMenu ? (
          <nav
            className={`header__nav nav-mobile-menu  nav ${
              isFixed ? 'fixed-mobile' : ''
            }`}
          >
            <ul className='nav__list'>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#services' className='nav__link'>
                  Послуги
                </a>
              </li>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#size' className='nav__link'>
                  Розміри
                </a>
              </li>
              <li className='nav__item' onClick={checkedMenu}>
                <a href='#faq' className='nav__link'>
                  Питання
                </a>
              </li>
            </ul>
          </nav>
        ) : null}
      </header>
    </>
  )
}

export default Header
