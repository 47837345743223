import { useNavigate } from 'react-router-dom'

import { useEffect, useMemo, useRef, useState } from 'react'

import validationForm from '../../utils/validation'

import SendFormService from '../../service/SendFormService'

import ok from '../../image/icons/free-icon-double-tick-1280891.png'
import photo from '../../image/icons/camera.svg'
import simsons from '../../image/services/simsons/simsons-popup.webp'
import standart from '../../image/services/standart-mix/standart-popup.webp'
import art from '../../image/services/art/art-popup.webp'
import blackWhite from '../../image/services/black-white/black-white-popup.webp'
import colague from '../../image/services/colague/colague-popup.webp'
import interier from '../../image/services/interier/interier.webp'
import loveIs from '../../image/services/love-is/love-is-popup.webp'
import time from '../../image/services/picture-with-clock/time-popup.webp'
import changeBg from '../../image/services/picture-with-exchange-phon/change_bg-popup.webp'
import simple from '../../image/services/simpe-picture-from-photo/simple-popup.webp'

import lineWhite from '../../image/line-white.png'

import simsonsMob from '../../image/services/simsons/simsons.webp'
import standartMob from '../../image/services/standart-mix/standart.webp'
import artMob from '../../image/services/art/art-popup-mob.webp'
import blackWhiteMob from '../../image/services/black-white/black-white.webp'
import colagueMob from '../../image/services/colague/colague.webp'
import interierMob from '../../image/services/interier/interier.webp'
import loveIsMob from '../../image/services/love-is/love-is-popup-mob.webp'
import timeMob from '../../image/services/picture-with-clock/time.webp'
import changeBgMob from '../../image/services/picture-with-exchange-phon/change_bg.webp'
import simpleMob from '../../image/services/simpe-picture-from-photo/simple.webp'

import './Modal.scss'

const data = [
  {
    img: art,
    imgMob: artMob,
    button: 'Арт-поєднання'
  },
  {
    img: colague,
    imgMob: colagueMob,
    button: 'Колаж',
    classes: 'modal__image-colague'
  },
  {
    img: simple,
    imgMob: simpleMob,
    button: 'Просто картина',
    classes: 'modal__image-simple'
  },
  {
    img: standart,
    imgMob: standartMob,
    button: 'Стандартне поєднання'
  },
  {
    img: loveIs,
    imgMob: loveIsMob,
    button: 'Love is'
  },

  {
    img: simsons,
    imgMob: simsonsMob,
    button: 'Сімпсони'
  },

  {
    img: time,
    imgMob: timeMob,
    button: 'Картина з годинником'
  },
  {
    img: interier,
    imgMob: interierMob,
    button: "Інтер'єрна картина",
    classes: 'modal__image-interier'
  },
  {
    img: blackWhite,
    imgMob: blackWhiteMob,
    button: 'З чорно-білої – кольорова'
  },

  {
    img: changeBg,
    imgMob: changeBgMob,
    button: 'Картина з заміною фону'
  }
]

const Modal = ({ close, size, setSize, typePicture }) => {
  const [disabled, setDisabled] = useState(true)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('+38')
  const [message, setMessage] = useState('')
  const [photos, setPhoto] = useState(null)

  const modalRef = useRef(null)

  const navigate = useNavigate()

  const { sendForm, sendFile } = SendFormService()

  useEffect(() => {
    if (
      !validationForm(name, 'name').errorStatus &&
      name !== '' &&
      phone !== '' &&
      !validationForm(phone, 'number').errorStatus
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [name, phone])

  useEffect(() => {
    console.log(name)
  }, [name])

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      close()
    }
  }

  const handleChange = (e) => {
    let value = e.target.value

    if (!value.startsWith('+38')) {
      console.log(value)
      value = '+38' + value.replace(/\D/g, '')
    } else {
      console.log(value)
      value = '+38' + value.slice(3).replace(/\D/g, '')
    }

    if (value.length > 13) {
      value = value.slice(0, 13)
    }

    setPhone(value)
  }

  const handleKeyDown = (e) => {
    if (
      (phone.length <= 3 && (e.key === 'Backspace' || e.key === 'Delete')) ||
      (e.key === 'a' && (e.ctrlKey || e.metaKey))
    ) {
      e.preventDefault()
    }
  }

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('Text')
    if (!pasteData.startsWith('+38')) {
      e.preventDefault()
    }
  }

  const formSubmit = async (e) => {
    e.preventDefault()

    const data = {
      name,
      phone,
      message,
      size
    }

    if (photos !== null) {
      sendForm(data)
        .then(() => sendFile(photos))
        .then(() => navigate('/thanks'))
        .catch((e) => console.error(e))
        .finally(() => {
          setName('')
          setPhone('')
          setMessage('')
          setPhoto('')
          setSize('')
        })
    } else {
      sendForm(data)
        .then(() => navigate('/thanks'))
        .catch((e) => console.error(e))
        .finally(() => {
          setName('')
          setPhone('')
          setMessage('')
          setPhoto('')

          close(false)
        })
    }
  }

  const handleFile = useMemo(() => {
    return typePicture
      ? data.filter((item) => item.button === typePicture)[0]
      : null
  }, [typePicture])

  return (
    <div className='modal' onClick={handleClickOutside}>
      <div className='modal__overlay' ref={modalRef}>
        {/* {window.innerWidth > 768 ? ( */}
        <>
          <img src={lineWhite} alt='line' className='modal__line' />
          <img src={lineWhite} alt='line' className='modal__bottom' />
          <span className='modal__close' onClick={() => close(false)}>
            &times;
          </span>
          <div className='modal__container'>
            <div className='modal__text'>
              <h3>Залиште ваші контактні дані,</h3>
              наш менеджер зв’яжеться та запропонує варіанти по оформленню
              ідеального подарунка
            </div>

            <div className='modal__image-containerMob'>
              <img
                src={handleFile.imgMob}
                alt={typePicture}
                className={`modal__image-mob `}
              />
            </div>

            <form className='modal__form modal-form' onSubmit={formSubmit}>
              <label htmlFor='name' className='modal-form__label'>
                <input
                  type='text'
                  id='name'
                  className='modal-form__input'
                  name='name'
                  placeholder='Ім’я'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {validationForm(name, 'name').errorStatus ? (
                  <div className='error'>Мінімум 2 символи</div>
                ) : null}
              </label>
              <label htmlFor='phone' className='modal-form__label'>
                <input
                  type='text'
                  id='phone'
                  name='phone'
                  className='modal-form__input'
                  placeholder='Номер телефону'
                  value={phone}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                  autoComplete='off'
                />
                {validationForm(phone, 'number').errorStatus ? (
                  <div className='error '>Формат +380999999999</div>
                ) : null}
              </label>

              <textarea
                className='modal-form__textarea'
                name='message'
                id=''
                placeholder='Коментар'

                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <div className='modal-form__send'>
                <div>
                  <label
                    htmlFor='avatar-change'
                    className='modal-form__changed-avatar '
                  >
                    <img src={photos === null ? photo : ok} alt='camera' />

                    <input
                      className='modal-form__input'
                      type='file'
                      id='avatar-change'
                      name='photo'
                   
                      onChange={(e) => {
                        setPhoto(e.target.files[0])
                        console.log(e)
                      }}
                    />
                  </label>
                  <span className='modal-form__send-text'>
                    Вставити зображення
                  </span>
                </div>

                <button
                  className='modal-form__submit '
                  disabled={disabled}
                >
                  Відправити
                </button>
              </div>
            </form>
          </div>
          <div className='modal__after-image'>
            <img
              src={handleFile.img}
              alt={typePicture}
              className={`modal__image ${handleFile.classes}`}
            />
          </div>
        </>
      </div>
    </div>
  )
}

export default Modal
