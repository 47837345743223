import art from '../../image/services/art/art.png'
import colague from '../../image/services/colague/colague.webp'
import blackAndWhite from '../../image/services//black-white/black-white.webp'
import interier from '../../image/services/interier/interier.webp'
import loveIs from '../../image/services/love-is/love-is.webp'
import changeBg from '../../image/services/picture-with-exchange-phon/change_bg.webp'
import simple from '../../image/services/simpe-picture-from-photo/simple.webp'
import standart from '../../image/services/standart-mix/standart.webp'
import simsons from '../../image/services/simsons/simsons.webp'
import time from '../../image/services/picture-with-clock/time.webp'

import './OurServices.scss'

export const servicesData = [
  {
    img: art,
    button: 'Арт-поєднання'
  },
  {
    img: colague,
    button: 'Колаж'
  },
  {
    img: simple,
    button: 'Просто картина',
    classes: 'services__img-rounded'
  },
  {
    img: standart,
    button: 'Стандартне поєднання'
  },
  {
    img: loveIs,
    button: 'Love is'
  },

  {
    img: simsons,
    button: 'Сімпсони'
  },

  {
    img: time,
    button: 'Картина з годинником'
  },
  {
    img: interier,
    button: "Інтер'єрна картина",
    classes: 'services__img-rounded'
  },
  {
    img: blackAndWhite,
    button: 'З чорно-білої – кольорова'
  },

  {
    img: changeBg,
    button: 'Картина з заміною фону',
    classes2: 'services__img-center'
  }
]
const OurServices = ({ modal, setTypePicture }) => {
  const renderServices = (data) => {
    return data.map((item) => {
      const { img, button, classes, classes2 } = item

      return (
        <li className={`services__item ${classes2}`} key={button}>
          <div className='services__image-container'>
            <img
              src={img}
              className={`services__img ${classes}`}
              onClick={() => {
                setTypePicture(button)
                modal(true)
              }}
              alt={button}
            />
          </div>
          <button
            onClick={() => {
              setTypePicture(button)
              modal(true)
            }}
            className='button services__description'
          >
            {button}
          </button>
        </li>
      )
    })
  }

  return (
    <>
      <a id='services'></a>
      <section className='services reveal'>
        <div className='services__bg1'></div>
        <div className='services__bg2'></div>

        <div className='services__container'>
          <h2 className='services__title'>Послуги</h2>

          <ul className='services__list'>{renderServices(servicesData)}</ul>
        </div>
      </section>
    </>
  )
}

export default OurServices
