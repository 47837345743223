import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const CustomSlider = ({settings, children}) => {
  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

export default CustomSlider