const SendFormService = () => {
  const botToken = '6838945956:AAF8F2gf3Lw_o1kWMZysdH_MHrgQMvZtIhw'
  const chatId = '-1002136952829'
  const url = `https://api.telegram.org/bot${botToken}/`

  const sendForm = async (formData) => {
    let size = ''
    let message = ''

    if (formData.size) {
      size = `Розмір картини: ${formData.size},`
    }

    if (formData.message) {
      message = `Повідомлення: ${formData.message},`
    }

    const transform = `
      Запит з сайту https://www.kartinynaholste.in.ua/
      Ім'я: ${formData.name},
      Номер телефону: ${formData.phone},
      ${size}
      ${message}
      
    `
    const data = {
      chat_id: chatId,
      text: transform
    }

    try {
      const request = await fetch(`${url}sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      if (!request.ok) {
      }
      return await request.json()
    } catch (e) {
      console.log(e)
      return Promise.resolve(e)
    }
  }

  const sendFile = async (file) => {
    console.log(file, file.name)
    const formData = new FormData()
    formData.append('chat_id', chatId)
    formData.append('photo', file, file.name)

    try {
      const res = await fetch(`${url}sendPhoto`, {
        method: 'POST',
        body: formData
      })

      if (res.ok) {
        return Promise.resolve(res)
      }

      console.log(res.status)
    } catch (e) {
      console.log(e)
      return Promise.resolve(e)
    }
  }

  return {
    sendForm,
    sendFile
  }
}

export default SendFormService
