import s20x30 from '../../image/size/20x30.webp'
import s25x35 from '../../image/size/25x35.webp'
import s30x40 from '../../image/size/30x40.webp'
import s30x50 from '../../image/size/30x50.webp'
import s40x50 from '../../image/size/40x50.webp'
import s40x60 from '../../image/size/40x60.webp'
import s50x70 from '../../image/size/50x70.webp'
import s60x90 from '../../image/size/60x90.webp'
import s70x100 from '../../image/size/70x100.webp'
import s90x120 from '../../image/size/90x120.webp'

import './Size.scss'

const data = [
  { src: s20x30, size: '20x30' },
  { src: s25x35, size: '25x35' },
  { src: s30x40, size: '30x40' },
  { src: s30x50, size: '30x50' },
  { src: s40x50, size: '40x50' },
  { src: s40x60, size: '40x60' },
  { src: s50x70, size: '50x70' },
  { src: s60x90, size: '60x90' },
  { src: s70x100, size: '70x100' },
  { src: s90x120, size: '90x120' }
]

export const servicesData = [
  {
    button: 'Арт-поєднання'
  },
  {
    button: 'Колаж'
  },
  {
    button: 'Просто картина',
    classes: 'services__img-rounded'
  },
  {
    button: 'Стандартне поєднання'
  },
  {
    button: 'Love is'
  },

  {
    button: 'Сімпсони'
  },

  {
    button: 'Картина з годинником'
  },
  {
    button: "Інтер'єрна картина",
    classes: 'services__img-rounded'
  },
  {
    button: 'З чорно-білої – кольорова'
  },

  {
    button: 'Картина з заміною фону',
    classes2: 'services__img-center'
  }
]

const Size = ({ modal, setTypePicture }) => {
  function getRandomDigit() {
    return Math.floor(Math.random() * 10)
  }

  return (
    <>
      <a id='size'></a>
      <div className='tariffs size reveal'>
        <h2 className=' size__title'>
          Доступні розміри для замовлення
        </h2>
        <ul className='size__list'>
          {data.map((item, i) => {
            const { src, size } = item
            return (
              <li className='size__list-item' key={size}>
                <div className='size__list-item-container'>
                <img src={src} alt={size} className='size__list-img'/>
                <button
                  className='size__button f-size-16'
                  onClick={() => {
                    setTypePicture(servicesData[getRandomDigit()].button)
                    modal(true)
                  }}
                >
                  {size}
                </button>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default Size
