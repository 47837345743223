
const validationForm = (value, name) => {

  switch(name) {
    case 'name':
      if (value.length > 0 && value.length < 2) {        
        return {
          errorStatus: true,
          message: 'Мінімум 2 символи',
          class: 'error',
          statusInput: false
        }
      } else if (value.length === 0) {
        return {
          errorStatus: false,
          message: '',
          class: '',
          statusInput: false
        }
      } else {
        return {
          errorStatus: false,
          message: '',
          class: '',
          statusInput: true
          
          
        }
      }
    
    case 'number':

      const regForNumber = value.match(/^(?:\+\d{1,4}\s?)?0\d{2}\s?(\d{3}\s?\d{2}\s?\d{2})$/);
          //  /^(?:\+?\d{1,4}\s?)?0\d{2}\s?(\d{3}\s?\d{2}\s?\d{2})$/
      const regForOnlyNum = value.match(/^[+0-9()\-]*$/);

      if (value.length <= 3) {
        return {
          errorStatus: false,
          message: '',
          class: '',
          statusInput: false
        }
      } else if (regForOnlyNum === null) {
        return {
          errorStatus: true,
          message: 'The phone number can be entered in numbers',
          class: 'error',
          statusInput: false
        }
      } else if (regForNumber === null) {
        return {
          errorStatus: true,
          message: 'Формат номеру +380999999999',
          class: 'error',
          statusInput: false
        }
      } else if (value.length > 13) {
        return {
          errorStatus: true,
          message: 'The phone number is no more than 13 characters',
          class: 'error',
          statusInput: false
        }
      } else {
        return {
          errorStatus: false,
          message: '',
          class: '',
          statusInput: true
        }
      }
   
    default:
      return null;
  }

  
}

export default validationForm;